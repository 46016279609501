import { useState } from 'react'

import { Box, Divider, Modal, MultiSelect } from '@mantine/core'
import { DateInput } from '@mantine/dates'

import { useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import StyledAlert from 'src/components/Feedback/StyledAlert/StyledAlert'
import {
  ASSIGN_TRAININGS,
  GET_APPLICANT_TRAININGS,
  GET_TRAININGS,
} from 'src/graphql/trainings.graphql'
import { cn } from 'src/utils'

import { toast } from '../Toast/Toast'

const TrainingsModal = ({ opened, close }) => {
  const [selectedLessons, setSelectedLessons] = useState([])
  const [deadline, setDeadline] = useState()
  const params = useParams()
  const applicantId = params.id

  const { data: availableData, loading: availableLoading } =
    useQuery(GET_TRAININGS)

  const [assignTrainings, { loading: assignLoading }] = useMutation(
    ASSIGN_TRAININGS,
    {
      onCompleted: () => {
        close()
      },
      onError: () => {
        toast(
          'Something went wrong. Please confirm that the lessons are not already assigned. ',
          'error'
        )
      },
      refetchQueries: [
        {
          query: GET_APPLICANT_TRAININGS,
          variables: {
            applicantId: applicantId,
          },
        },
      ],
    }
  )

  const availableTrainings = availableData?.trainings?.items || []

  const lessonData = availableTrainings.map((training) => {
    return {
      label: training.name,
      value: training.trainingId,
      description: training.description,
      duration: training.duration,
      language: training.language,
    }
  })

  const RenderSelectOption = ({ option }) => {
    const [expanded, setExpanded] = useState(false)
    return (
      <div className="flex flex-col gap-1 font-medium text-doubleNickel-gray-900">
        <div className="flex flex-row items-center gap-2">
          {option.label}
          {option?.language && (
            <div className="font-normal text-doubleNickel-gray-600">{`[${option.language}]`}</div>
          )}
          <div className="ml-auto font-normal">{option.duration}</div>
        </div>

        <div className="font-normal text-doubleNickel-gray-700">
          <div className="flex flex-col gap-1 font-normal text-doubleNickel-gray-700">
            <div className={cn(expanded ? 'line-clamp-none' : 'line-clamp-1')}>
              {option.description}
            </div>
            <Box
              className="text-xs font-medium text-doubleNickel-brand-400 hover:cursor-pointer hover:underline"
              onClick={(e) => {
                e.stopPropagation()
                setExpanded(!expanded)
              }}
            >
              {expanded ? 'Hide' : 'Show more'}
            </Box>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="45%"
      classNames={{
        title: 'text-xl font-bold',
        body: 'pt-4',
      }}
      title="Assign a lesson"
      yOffset="20vh"
    >
      <div className="flex min-h-[300px] flex-col gap-4">
        <MultiSelect
          label="Select the lessons you would like to assign"
          placeholder="Select"
          searchable
          hidePickedOptions
          withAsterisk={true}
          required
          data={lessonData}
          value={selectedLessons}
          maxDropdownHeight={400}
          onChange={(value) => setSelectedLessons(value)}
          renderOption={RenderSelectOption}
        />
        <DateInput
          label="Deadline"
          placeholder="Select date"
          maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
          value={deadline}
          onChange={(date) => setDeadline(date)}
        />

        {!availableTrainings.length && !availableLoading && (
          <StyledAlert
            variant="warning"
            description={
              <span data-testid="warning-message">
                No lessons available to assign.
                <br />
                Please contact the Double Nickel team to set up your training
                integration!
              </span>
            }
          />
        )}

        <div className="mt-auto">
          <Divider my="md" />
        </div>
        <div className="grid grid-cols-2 gap-3">
          <Button
            text="Cancel"
            className="text-base"
            variant="outline"
            onClick={close}
          />
          <Button
            text="Confirm"
            className="text-base"
            loading={assignLoading}
            onClick={() => {
              assignTrainings({
                variables: {
                  applicantId: applicantId,
                  trainingIds: selectedLessons,
                  deadline: deadline,
                },
              })
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default TrainingsModal

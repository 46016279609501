import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ActionIcon, Box, Indicator, Menu, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'
import { ActivityLog } from 'types/graphql'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { CallStatus } from 'src/context/PhoneDeviceContext'
import {
  DOWNLOAD_RECORDING_URL,
  DOWNLOAD_TRANSCRIPT_URL,
} from 'src/graphql/activityLogs.graphql'
import IconThreeDots from 'src/icons/IconThreeDots'
import { buildFullName, formatSnakeValue } from 'src/lib/formatters'
import { cn } from 'src/utils'
import { downloadFile } from 'src/utils/downloadFile'

const DropdownMenu = ({ activityLog }) => {
  const [downloadRecording, { called, data: downloadResponse }] = useLazyQuery(
    DOWNLOAD_RECORDING_URL,
    {
      onCompleted: () => {
        if (called && downloadResponse?.getRecordingDownloadUrl?.url) {
          const { url, fileName } = downloadResponse.getRecordingDownloadUrl
          downloadFile({ url, fileName })
        }
      },
      onError: () => {
        toast('Something went wrong, please try again.', 'error')
      },
    }
  )

  const [
    downloadTranscript,
    { called: calledTranscript, data: downloadTranscriptResponse },
  ] = useLazyQuery(DOWNLOAD_TRANSCRIPT_URL, {
    onCompleted: () => {
      if (
        calledTranscript &&
        downloadTranscriptResponse?.getTranscriptDownloadUrl?.url
      ) {
        const { url, fileName } =
          downloadTranscriptResponse.getTranscriptDownloadUrl
        downloadFile({ url, fileName })
      }
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const handleRecording = useCallback(() => {
    downloadRecording({
      variables: {
        activityLogId: activityLog.activityLogId,
      },
    })
  }, [activityLog, downloadRecording])

  const handleTranscript = useCallback(() => {
    downloadTranscript({
      variables: {
        activityLogId: activityLog.activityLogId,
      },
    })
  }, [activityLog, downloadTranscript])

  return (
    <Menu shadow="md" position="bottom-end">
      <Menu.Target>
        <ActionIcon variant="subtle" onClick={(e) => e.stopPropagation()}>
          <IconThreeDots className="h-4 fill-doubleNickel-white stroke-doubleNickel-gray-600" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown className="w-40">
        <Menu.Item
          className="text-doubleNickel-gray-700"
          disabled={!activityLog.recordingSid}
          onClick={() => {
            handleRecording()
          }}
        >
          Download audio recording
        </Menu.Item>
        <Menu.Item
          className="text-doubleNickel-gray-700"
          disabled={!activityLog.transcriptFileUrl}
          onClick={() => {
            handleTranscript()
          }}
        >
          Download transcript
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

interface CallLogCardProps {
  activityLog: ActivityLog
  handleSelect: (activityLog: ActivityLog) => void
}

const CallRow = ({ activityLog, handleSelect }: CallLogCardProps) => {
  console.log(activityLog)
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isVirtualCall = activityLog.callType === 'BLANDAI'
  const label = isOutboundCall ? 'Outbound' : 'Inbound'
  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callStatus = activityLog?.callStatus
  const isLeftVoiceMail = activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callDurationInSeconds = dayjs(activityLog.endCallDate).diff(
    dayjs(activityLog.startCallDate),
    'seconds'
  )
  const callInMinutes = Math.floor(callDurationInSeconds / 60)
  const remindingSeconds = callDurationInSeconds % 60
  const isManualRegistered = activityLog.callStatus === null
  const voiceMailTitle = activityLog.voiceMailTemplate?.title
  const recruiter = isVirtualCall
    ? 'VoiceAgent'
    : activityLog?.employee
    ? buildFullName(activityLog.employee)
    : 'Employee not found'

  const handleTranscripts = () => {
    handleSelect(activityLog)
  }

  return (
    <Box
      className={`border-bg-doubleNickel-gray-600 flex cursor-pointer flex-row items-center justify-between gap-1 px-2 py-1 text-xs text-doubleNickel-gray-500 hover:bg-doubleNickel-gray-100`}
      onClick={handleTranscripts}
    >
      <div className={`grid flex-1 grid-cols-12 `}>
        <div className="col-span-1 my-auto">
          {dayjs(activityLog.activityDate).isValid()
            ? dayjs(activityLog.activityDate).format('MM/DD/YYYY')
            : 'Invalid Date'}
        </div>
        <div className="col-span-1 my-auto">
          {dayjs(activityLog.activityDate).isValid()
            ? dayjs(activityLog.activityDate).format('hh:mm A')
            : 'Invalid Date'}
        </div>
        <Tooltip label={recruiter} position="top">
          <div
            className={cn(
              'col-span-1 my-auto w-full overflow-hidden text-ellipsis',
              isVirtualCall && 'text-doubleNickel-brand-500'
            )}
          >
            {recruiter}
          </div>
        </Tooltip>
        <div className="my-auto">
          {isOutboundCall ? '→' : '←'} {label}
        </div>

        <div
          className={cn(
            isMissedCall && 'font-medium text-doubleNickel-error-500',
            activityLog?.callStatus === CallStatus.COMPLETED &&
              'font-medium text-doubleNickel-success-500',
            'col-span-1 mx-auto my-auto'
          )}
        >
          {isManualRegistered ? 'Manual' : formatSnakeValue(callStatus)}
        </div>
        <div className="col-span-1 mx-auto my-auto">
          {!isNaN(callInMinutes)
            ? `${callInMinutes}:${remindingSeconds.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })} min`
            : '0:00 min'}
        </div>
        <div className="col-span-6 my-auto flex flex-row items-center gap-1 overflow-hidden">
          <div className="mr-auto line-clamp-1 flex-1 text-ellipsis text-nowrap text-black">
            {activityLog?.transcriptSummary &&
            activityLog?.transcriptSummary !== 'NO_ANSWER'
              ? activityLog.transcriptSummary
              : ' '}
            {isLeftVoiceMail && voiceMailTitle ? voiceMailTitle : ''}
          </div>
          <div className="flex max-w-[50%] flex-row items-center gap-1 overflow-auto">
            {activityLog?.labels.map((label) => (
              <div
                key={label}
                className="rounded-2xl border-[1px] border-doubleNickel-gray-300 py-1 pl-5 pr-2 text-doubleNickel-gray-800"
              >
                <Indicator position="middle-start" size={4} offset={-8}>
                  {label}
                </Indicator>
              </div>
            ))}
          </div>
        </div>
      </div>
      <DropdownMenu activityLog={activityLog} />
    </Box>
  )
}

export default CallRow

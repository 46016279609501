/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect } from 'react'

import { NetworkStatus } from '@apollo/client'
import { Drawer, Loader } from '@mantine/core'
import dayjs from 'dayjs'
import { useInView } from 'react-intersection-observer'

import { useQuery } from '@redwoodjs/web'

import Empty from 'src/components/Feedback/Empty/Empty'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { DOWNLOAD_RECORDING_URL } from 'src/graphql/activityLogs.graphql'
import { GET_CALL_TRANSCRIPTS } from 'src/graphql/callTranscripts.graphql'
import { cn } from 'src/utils'

const CallDetailsDrawer = ({ activityLog, drawerOpened, drawerHandlers }) => {
  const LIMIT = 20
  const page = 1
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { data, fetchMore, networkStatus, loading } = useQuery(
    GET_CALL_TRANSCRIPTS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        activityLogId: activityLog.activityLogId,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
      },
      onError: () => {
        toast('Unable to get transcript file', 'error')
      },
    }
  )

  const transcriptData = data?.callTranscripts.items ?? []

  const { data: recordingUrl } = useQuery(DOWNLOAD_RECORDING_URL, {
    variables: {
      activityLogId: activityLog.activityLogId,
    },
    onError: () => {
      toast('Unable to get audio file', 'error')
    },
  })

  useEffect(() => {
    if (inView) {
      fetchMore({
        variables: {
          pageInfo: {
            offset: transcriptData.length ?? 0,
            limit: LIMIT,
          },
        },
      })
    }
  }, [inView])

  return (
    <Drawer
      opened={drawerOpened}
      withCloseButton={true}
      onClose={drawerHandlers.close}
      position="right"
      closeOnClickOutside={true}
      size="24vw"
      removeScrollProps={{ enabled: true, removeScrollBar: false }}
      title="Call Details"
      overlayProps={{
        backgroundOpacity: 0.1,
      }}
      classNames={{
        title: 'text-base font-semibold text-doubleNickel-gray-900 ',
        body: 'flex flex-col gap-10',
      }}
    >
      <div className="flex h-[90vh] flex-col overflow-auto">
        <div className="mb-2 flex flex-row items-center justify-between text-sm font-medium">
          Summary
          <div>
            {dayjs(activityLog.activityDate).isValid() && (
              <div className="ml-auto text-sm text-doubleNickel-gray-500">
                {dayjs(activityLog.activityDate).format('MM/DD/YYYY hh:mm A')}
              </div>
            )}
          </div>
        </div>
        <div className="mb-4 text-sm text-doubleNickel-gray-700">
          {activityLog?.transcriptSummary}
        </div>

        {transcriptData.length > 0 ? (
          <div className="mb-14 flex flex-col gap-2">
            {transcriptData.map((transcript) => (
              <div
                key={transcript.callTranscriptId}
                className={cn(
                  'max-w-[90%] rounded p-2 text-sm',
                  transcript.user === 'AGENT' &&
                    'ml-auto bg-doubleNickel-brand-50',
                  transcript.user === 'HUMAN' &&
                    'mr-auto bg-doubleNickel-gray-200'
                )}
              >
                {transcript.content}
              </div>
            ))}
            {networkStatus === NetworkStatus.fetchMore && (
              <Loader size="sm" className="my-4 flex w-full justify-center" />
            )}
            {transcriptData.length < data?.callTranscripts.totalCount &&
              !loading && (
                <div ref={ref} className="opacity-0">
                  Inview placeholder
                </div>
              )}
          </div>
        ) : (
          <Empty
            title={'No transcript available'}
            subtitle="We're temporarily unable to parse this transcript"
          />
        )}
      </div>

      <div className="fixed bottom-0 left-4 right-4 flex h-[8vh] flex-col justify-center bg-white">
        <audio
          src={recordingUrl?.getRecordingDownloadUrl?.url || ''}
          controls
          className="h-10 w-full rounded bg-[#F1F3F4]"
        >
          Your browser does not support the audio element.
        </audio>
      </div>
    </Drawer>
  )
}

export default CallDetailsDrawer

export const GET_CALL_TRANSCRIPTS = gql`
  query GetCallTranscripts($activityLogId: String!, $pageInfo: PageInfoInput) {
    callTranscripts(activityLogId: $activityLogId, pageInfo: $pageInfo) {
      items {
        callTranscriptId
        createdAt
        updatedAt
        date
        user
        type
        content
      }
      pageInfo {
        offset
        limit
      }
      totalCount
    }
  }
`

import { Table } from '@mantine/core'
import dayjs from 'dayjs'

import { useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import Empty from 'src/components/Feedback/Empty/Empty'
import { GET_APPLICANT_TRAININGS } from 'src/graphql/trainings.graphql'
import { formatSnakeValue } from 'src/lib/formatters'
import { buildFullName } from 'src/lib/formatters'
const TrainingTable = () => {
  const params = useParams()
  const applicantId = params.id

  const { data, loading, error } = useQuery(GET_APPLICANT_TRAININGS, {
    variables: {
      applicantId: applicantId,
    },
  })

  const lessons = data?.applicantTrainings?.items || []

  console.log('Lessons:', lessons)

  const rows = loading ? (
    <></>
  ) : (
    lessons.map((lesson) => {
      const hasParentCourse = lesson.applicantCourses.length > 0

      return (
        <Table.Tr
          key={lesson.trainingId}
          className="text-sm text-doubleNickel-gray-900"
        >
          <Table.Td className="text-nowrap py-2 pl-4">
            <div className="flex flex-col">
              {lesson.training.name}
              {hasParentCourse && (
                <div className="text-xs">
                  {lesson.applicantCourses[0].applicantCourse.course.name}
                </div>
              )}
            </div>
          </Table.Td>
          <Table.Td>
            <StyledBadge
              textTransform="capitalize"
              color={lesson.status === 'NOT_STARTED' ? 'gray' : 'green'}
            >
              {formatSnakeValue(lesson.status)}
            </StyledBadge>
          </Table.Td>
          <Table.Td>
            {lesson.training?.duration
              ? lesson.training.duration + ' minutes'
              : 'Unavailable'}
          </Table.Td>
          <Table.Td>{buildFullName(lesson.employee)}</Table.Td>
          <Table.Td>{dayjs(lesson.createdAt).format('MM/DD/YYYY')}</Table.Td>
          <Table.Td>
            {lesson?.endDate
              ? dayjs(lesson.endDate).format('MM/DD/YYYY')
              : 'Incomplete'}
          </Table.Td>
          <Table.Td>
            {lesson?.deadline
              ? dayjs(lesson.deadline).format('MM/DD/YYYY')
              : 'Not assigned'}
          </Table.Td>
        </Table.Tr>
      )
    })
  )

  return (
    <>
      <Table highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr className="h-11 border-none bg-doubleNickel-gray-50 text-xs text-doubleNickel-gray-600">
            <Table.Th className="p-4">Lesson Name</Table.Th>
            <Table.Th className="py-4">Status</Table.Th>
            <Table.Th className="py-4">Duration</Table.Th>
            <Table.Th className="py-4">Assigned By</Table.Th>
            <Table.Th className="py-4">Assigned Date</Table.Th>
            <Table.Th className="py-4">Completed Date</Table.Th>
            <Table.Th className="py-4">Due Date</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
        <Table.Caption>
          {lessons.length === 0 && !loading && (
            <Empty
              title="No Active Trainings"
              subtitle="Assign a training to get started"
            />
          )}
        </Table.Caption>
      </Table>
    </>
  )
}

export default TrainingTable

import { Box, Divider, Menu } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import Button from 'src/components/Buttons/Button/Button'
import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import CoursesModal from 'src/components/Overlays/TrainingsModal/CoursesModal'
import TrainingsModal from 'src/components/Overlays/TrainingsModal/TrainingsModal'
import IconChevronDown from 'src/icons/IconChevronDown'

import TrainingTable from './components/TrainingTable'

const TrainingTab = () => {
  const [openedLessons, { open: openLessonsModal, close: closeLessonsModal }] =
    useDisclosure(false)
  const [openedCourses, { open: openCoursesModal, close: closeCoursesModal }] =
    useDisclosure(false)

  return (
    <div className="flex h-full flex-col gap-6 overflow-auto pb-20">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col text-lg font-semibold text-doubleNickel-black">
          {'Training'}
          <div className="text-sm font-medium text-doubleNickel-gray-700">
            Assign and track training for this applicant
          </div>
        </div>
        <StyledMenu shadow="md" position="bottom-end">
          <Menu.Target>
            <Box className="font-lg flex flex-row items-center justify-between font-bold text-doubleNickel-gray-700">
              <Button
                text="Assign"
                righticon={
                  <IconChevronDown className="h-3 fill-none stroke-doubleNickel-gray-600" />
                }
              />
            </Box>
          </Menu.Target>
          <Menu.Dropdown className="w-40">
            <Menu.Item
              className={` text-doubleNickel-gray-700`}
              onClick={openCoursesModal}
            >
              Assign Courses
            </Menu.Item>
            <Menu.Item
              className="text-doubleNickel-gray-700"
              onClick={openLessonsModal}
            >
              Assign Lessons
            </Menu.Item>
          </Menu.Dropdown>
        </StyledMenu>
      </div>

      <TrainingTable />
      <Divider />
      {/* <TrainingAuditTrail /> */}
      {openedLessons && (
        <TrainingsModal opened={openedLessons} close={closeLessonsModal} />
      )}
      {openedCourses && (
        <CoursesModal opened={openedCourses} close={closeCoursesModal} />
      )}
    </div>
  )
}

export default TrainingTab
